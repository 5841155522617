import { Action, CsrfToken, HashedCsrfToken } from '../types';

export const updateCsrfToken = (csrfToken: CsrfToken): Action => ({
  type: 'CSRF_TOKEN_UPDATE',
  csrfToken,
});

export const updateHashedCsrfToken = (hashedCsrfToken: HashedCsrfToken): Action => ({
  type: 'HASHED_CSRF_TOKEN_UPDATE',
  hashedCsrfToken,
});
