import { createContext, useContext } from 'react';
import { ConfigClient, ConfigCollection } from '@atlaskit/web-config-client';
import { getCurrentEnv } from '../env';
import { getStatsigApiKey, statsigTargetApp } from '../../providers/common';
import { captureException } from '../analytics/error-reporting';
import type { ConfigResult } from '@atlaskit/config-common-libs/dist/types/services/types';
import { AidFrontendDynamicConfig } from '../../providers/DynamicConfigs';

const getDynamicConfigsClientBaseUrl = () => {
  // Provided by https://atlaskit.atlassian.com/packages/measurement/web-config-client
  switch (getCurrentEnv()) {
    case 'dev':
    case 'local':
      return 'https://api.dev.atlassian.com/flags';
    case 'stg':
      return 'https://api.stg.atlassian.com/flags';
    case 'stg-fedm':
    case 'prod-fedm':
      // see https://home.atlassian.com/o/2346a038-3c8c-498b-a79b-e7847859868d/s/a436116f-02ce-4520-8fbb-7301462a1674/project/ATLAS-74313/about to track FedRAMP availability
      console.warn('Dynamic configs are not currently supported in FedRAMP environments');
      return null;
    case 'prod':
    default:
      return 'https://api.atlassian.com/flags';
  }
};

export const FETCH_TIMEOUT_MS = 10000;

export const initializeDynamicConfigs = async (analyticsAnonymousId: string) => {
  const baseUrl = getDynamicConfigsClientBaseUrl();

  if (baseUrl === null) {
    return ConfigCollection.empty();
  }

  try {
    return await ConfigClient.fetch({
      ffsBaseUrl: baseUrl,
      ffsApiKey: getStatsigApiKey(),
      context: {
        namespace: statsigTargetApp,
        // this uses a subset of the identifiers that the feature gates client uses. We can add more in future if they are needed.
        identifiers: {
          analyticsAnonymousId,
        },
        metadata: {},
      },
      fetch: (input: RequestInfo | URL, init?: RequestInit) =>
        fetch(input, {
          ...init,
          signal: AbortSignal?.timeout?.(FETCH_TIMEOUT_MS),
        }),
    });
  } catch (error) {
    captureException(error);
    console.error(
      'Error creating Statsig dynamic config collection. Falling back to empty collection.'
    );
    return ConfigCollection.empty();
  }
};

export const DynamicConfigsContext = createContext<AidFrontendConfigCollection | undefined>(
  undefined
);

type ConfigMethod<T> = (configName: AidFrontendDynamicConfig) => ConfigResult<T>;

// this type is a facade for ConfigCollection.
// it enforces that dynamic config keys are sourced from the AidFrontendDynamicConfig enum instead of accepting any string.
export type AidFrontendConfigCollection = {
  getBoolean: ConfigMethod<boolean>;
  getString: ConfigMethod<string>;
  getNumber: ConfigMethod<number>;
  getStringList: ConfigMethod<string[]>;
  getNumberList: ConfigMethod<number[]>;
};

/**
 * Hook to access the Statsig dynamic config collection.
 *
 * The returned collection will be empty if there was a configuration error or when running
 * in FedRAMP environments (FedRAMP is not yet supported).
 */
export const useDynamicConfigs = (): AidFrontendConfigCollection => {
  const context = useContext(DynamicConfigsContext);

  if (!context) {
    throw new Error('useDynamicConfigsClient used outside of DynamicConfigsClientProvider.');
  }

  return context;
};
