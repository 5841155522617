import Spinner from '@atlaskit/spinner';
import { gridSize } from '@atlaskit/theme';
import styled from '@emotion/styled';
import React from 'react';

export const FullScreenSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${gridSize() * 3}px;
`;

export interface LoadingSpinnerProps {
  testId: string;
}

export const LoadingSpinner = ({ testId }: LoadingSpinnerProps) => {
  return (
    <SpinnerContainer>
      <Spinner testId={testId} size="large" />
    </SpinnerContainer>
  );
};
