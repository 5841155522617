import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../fetch-api';
import { idacOrigin } from '../env';
import { LoginStateResult } from './useLoginState';
import { SignupVerifyEmailOtpStateResult } from '../../pages/signup/verify-email/otp';
import { useSearchParams } from 'react-router-dom';
import { SignupWelcomeStateResult } from '../../pages/signup/welcome/hooks/useWelcomeState';
import { useAppDispatch } from '../../stores/redux.store';
import { useEffect } from 'react';
import { updateCsrfToken, updateHashedCsrfToken } from '../../actions/csrf-token-actions';

export interface BaseStateResult {
  type: 'redirect' | 'page-state';
}

export interface BaseRedirectState extends BaseStateResult {
  type: 'redirect';
  uri: string;
}

export interface BasePageState extends BaseStateResult {
  type: 'page-state';
  csrfToken?: string;
  hashedCsrfToken?: string;
}

export type PathsToStateResults = {
  '/login/state': LoginStateResult;
  '/signup/verify-email/otp/state': SignupVerifyEmailOtpStateResult;
  '/signup/welcome/state': SignupWelcomeStateResult;
};

/**
 * Source of truth for enabling SAS /state endpoint fetches.
 * Currently disabled as we've decided to consistently use useGlobalPageState,
 * to prevent inconsistent behaviour while we roll out SAS M3.
 * To be re-enabled for SAS M4.
 * */
export const isSasStateEndpointEnabled = () => false;

export const usePageState = <
  Path extends keyof PathsToStateResults,
  State extends PathsToStateResults[Path]
>(
  path: Path,
  options: {
    enabled: boolean;
  } = {
    enabled: true,
  }
) => {
  const { enabled } = options;
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const { data, isLoading, isError, isSuccess, error } = useQuery({
    queryKey: [path],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const params = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
      const response = await fetchApi(`${idacOrigin}${path}${params}`);
      return (await response.json()) as State;
    },
    enabled,
  });

  useEffect(() => {
    if (isPageStateResult(data)) {
      const { csrfToken, hashedCsrfToken } = data;
      if (csrfToken) {
        dispatch(updateCsrfToken(csrfToken));
      }
      if (hashedCsrfToken) {
        dispatch(updateHashedCsrfToken(hashedCsrfToken));
      }
    }
  }, [isSuccess, data, dispatch]);

  return {
    state: data,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export const isRedirectResult = (result?: BaseStateResult): result is BaseRedirectState => {
  return result?.type === 'redirect';
};

export const isPageStateResult = (result?: BaseStateResult): result is BasePageState => {
  return result?.type === 'page-state';
};
