export enum AidFrontendDynamicConfig {
  // https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/aid-frontend_disabled_cobranding_application_keys
  DISABLED_COBRANDING_APPLICATION_KEYS = 'aid-frontend_disabled_cobranding_application_keys',
  // https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/google_login_outage_advisory
  GOOGLE_LOGIN_OUTAGE_ADVISORY = 'google_login_outage_advisory',
  // https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/aid-frontend_apple_login_outage_advisory
  APPLE_LOGIN_OUTAGE_ADVISORY = 'aid-frontend_apple_login_outage_advisory',
  // https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/aid-frontend_slack_login_outage_advisory
  SLACK_LOGIN_OUTAGE_ADVISORY = 'aid-frontend_slack_login_outage_advisory',
  // https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/aid-frontend_microsoft_login_outage_advisory
  MICROSOFT_LOGIN_OUTAGE_ADVISORY = 'aid-frontend_microsoft_login_outage_advisory',
  // https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/dynamic_configs/aid-frontend_google_one_tap_kill_switch
  GOOGLE_ONE_TAP_KILL_SWITCH = 'aid-frontend_google_one_tap_kill_switch',
}
